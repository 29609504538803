
import { format } from "date-fns";
import Vue from "vue";
import { sendGridEventTypes } from "@/helpers/activities";

export interface IData {
  eventTypes: Record<string, any>;
}

export default Vue.extend({
  name: "EmailRecipientsExpandNode",
  props: {
    sendgridActivities: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted() {
    this.sendgridActivities.sort(function(a: any, b: any) {
      return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
    });
  },
  data(): IData {
    return {
      eventTypes: sendGridEventTypes
    };
  },
  computed: {
    activities(): any {
      return this.sendgridActivities.map((activity: any) => {
        const { event, url, timestamp } = activity.data;
        const data: Record<string, any> = {
          content: event,
          timestamp: this.formatDate(timestamp * 1000) //timestamp is unix_time - seconds since unix epoch. Date constructor takes milliseconds. so we had to
        };
        data.type = this.eventTypes[event] || "info";
        if (event == "processing") {
          data.color = "#86b6ed";
        }
        if (data.content === "click") {
          data.content = `${data.content} ${url}`;
        }
        return data;
      });
    }
  },
  methods: {
    formatDate(dateString: number) {
      return format(new Date(dateString), "M/d/yyyy h:mm:ss a");
    }
  }
});
